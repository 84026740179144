/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import '@ionic/angular/css/ionic-swiper';
@import './app/components/auto-complete/auto-complete.component';

@font-face {
  font-family: 'Lato';
  src: url('./assets/font/Lato/Lato-Regular.ttf');
}

.md,
.ios {

  .menu-logo {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--dark_bg_color);
    z-index: 2;

    img {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: block;
      padding-bottom: 8px;
      padding-top: 5px;
    }
  }

  .menu-close {
    position: fixed;
    right: 10px;
    top: calc(var(--ion-safe-area-top) + 10px);
    width: 22px;
    height: 22px;
    z-index: 2;
    cursor: pointer;
    display: none;

    &.native {
      top: 38px;
    }

    @media (max-width: 375px) {
      display: block;
    }
  }

  .alert-message {
    span {
      text-align: left;
      display: block;
    }
  }

  app-auto-complete {
    width: 100%;

    ion-searchbar {
      padding: 0 !important;
    }

    ul {
      width: 100%;
      left: 0;

      & > li {
        padding: 0;

        span {
          padding: 15px;
          display: block;
        }
      }
    }

    .loading input.searchbar-input {
      background-position: right 36px center;
    }
  }

  .searchbar-input {
    height: 58px;
    box-shadow: 0 0 0;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    background: #FFFFFF;
    color: #232323;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 65px;
  }

  .rtl {
    .searchbar-input {
      padding-right: 20px;
      padding-left: 65px;
    }

    .header-search-filters {
      right: 10px !important;
      left: auto;
    }
  }

  .success {
    .searchbar-input {
      border-color: #27AE60;
    }
  }

  .searchbar-search-icon {
    top: 18px;
    left: auto;
    right: 12px;
  }

  .rtl {
    .searchbar-search-icon {
      left: 16px !important;
      right: unset !important;
    }

    .loading {
      input.searchbar-input {
        background-position: left 42px center;
      }
    }
  }

  .searchbar-clear-button {
    display: none !important;
  }

  ion-title {
    color: #ffffff;
    font-size: 22px;

    &.black {
      color: #000000;
    }

    &.left {
      text-align: left;
    }

    @media (max-width: 370px) {
      font-size: 19px;
      padding-top: 0 !important;
      padding-left: 10px;
      padding-right: 10px;
    }

    .small {
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;

      a {
        color: #ffffff;
      }
    }
  }

  .buttons-last-slot {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;

      & > li {
        float: left;
        margin-left: 20px;

        @media (max-width: 760px) {
          margin-left: 10px;
          margin-right: 5px;
        }

        a {
          color: #ffffff;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 16px;

          @media (max-width: 760px) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .avatar {
    height: 42px;
    width: 42px;
    background-color: #525974;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    line-height: 42px;
    text-transform: uppercase;
  }

  ion-header {
    padding-top: 0;
    background-color: #F8F8F9;
    z-index: 999;
    box-shadow: 0 0 0;

    &.dark {
      background: #10111C;

      .header-btn {
        --ion-color-dark: #ffffff;
        --ion-color-dark-shade: #ffffff;
        --ion-color-dark-tint: #ffffff;
      }

      ion-toolbar {
        ion-title {
          padding-inline-end: 55px;
          -webkit-padding-end: 55px;
        }
      }
    }

    &.white {
      background: #ffffff;
    }

    ion-toolbar {
      .buttons-first-slot {
        img {
          margin-left: 10px;
        }
      }
    }
  }

  ion-select {
    border: #bfbfc6 1px solid;
    border-radius: 3px;
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;

    &.form-controll {
      width: 50%;
    }
  }

  ion-alert {
    button[disabled] {
      .alert-radio-label {
        opacity: 0.3;
      }

      .alert-radio-icon {
        opacity: 0.3;
      }

    }
  }

  ion-input {
    border: #bfbfc6 1px solid;
    border-radius: 4px;
    background-color: #ffffff;

    --padding-top: 8px;
    --padding-bottom: 8px;
    --padding-start: 10px !important;
  }

  ion-textarea {
    border: #bfbfc6 1px solid;
    border-radius: 3px;
    background-color: #ffffff;
    --padding-start: 10px !important;
  }

  .header-title {
    background: var(--dark_bg_color);
    height: 55px;
    line-height: 55px;
    color: #D8D8D8;
    padding-left: 15px;
    font-size: 17px;

    .wrap {
      max-width: 840px;
      margin-left: auto;
      margin-right: auto;
    }

    .icon {
      font-size: 40px;
      position: relative;
      top: 9px;
      margin-right: 10px;
    }
  }

  ion-toolbar {

    ion-title {
      padding-top: 0;
      padding-inline-start: 0;
      -webkit-padding-start: 0;
      padding-inline-end: 15px;
      -webkit-padding-end: 15px;

      @media (max-width: 767px) {
        text-align: center;
      }

      &.without-back-btn {
        padding-inline-start: 15px;
        -webkit-padding-start: 15px;
      }

      .header-search {
        position: relative;

        &-icon {
          position: absolute;
          left: 10px;
          top: 11px;
          z-index: 5;

          ion-icon {
            color: #97989B;
            font-size: 22px;
          }
        }

        &-filters {
          position: absolute;
          left: 38px;
          top: 13px;
          z-index: 5;
          color: #333333;
          font-size: 16px;
          text-transform: capitalize;

          &.orange {
            color: #f29345;
          }

          @media (max-width: 767px) {
            font-size: 14px;
          }

          .pill {
            background: #F2F3F8;
            border-radius: 22px;
            padding-left: 5px;
            padding-right: 5px;
            color: #666670;
            font-size: 14px;
            line-height: 22px;
            padding-top: 4px;
            padding-bottom: 4px;
            position: relative;
            cursor: pointer;
            font-weight: 500;

            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 18px;
            }

            .close {
              position: relative;
              top: 3px;
              width: 14px;
              display: inline-block;

              @media (max-width: 767px) {
                width: 12px;
              }
            }
          }
        }

        &-action {
          position: absolute;
          right: 8px;
          top: 7px;
          z-index: 5;
          cursor: pointer;
          border-radius: 4px;

          img {
            display: block;
          }

          @media (max-width: 767px) {
            right: 6px;
            top: 6px;
          }

          &.second {
            right: 45px;

            @media (max-width: 767px) {
              right: 40px;
            }
          }
        }

        ion-input {
          background: #ffffff;
          color: #444444;
          text-align: left;
          text-transform: none;
          font-size: 16px;
          --padding-top: 12px;
          --padding-bottom: 12px;
          border-radius: 40px !important;
          border: 1px solid #F2F3F8;
          font-weight: 500;

          @media (max-width: 767px) {
            max-width: 100%;
            font-size: 14px;
          }
        }

        &.with-actions {
          ion-input {
            --padding-end: 65px;

            @media (max-width: 767px) {
              --padding-end: 57px;
            }
          }

          &.one-action {
            ion-input {
              --padding-end: 42px;
              --padding-start: 38px !important;

              @media (max-width: 767px) {
                --padding-end: 15px;
              }
            }
          }
        }
      }

      .search-examples {
        overflow: hidden;
        height: 18px;
        position: relative;

        &.collapsed {
          height: auto;
          margin-top: 7px;

          .search-examples-list:after {
            display: none;
          }

          .search-examples-control {
            bottom: 9px;
          }
        }

        &-control {
          position: absolute;
          right: 0;
          bottom: -4px;
          font-size: 18px;
          cursor: pointer;
        }

        &-more {
          position: absolute;
          right: 40px;
          bottom: 12px;
          font-size: 15px;

          @media (max-width: 767px) {
            display: none;
          }

          a {
            color: #53DEFC;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &-title {
          font-size: 15px;
          margin-bottom: 2px;
          font-weight: bold;
          text-transform: lowercase;
          text-align: left;
          margin-left: auto;
          margin-right: auto;

          @media (max-width: 767px) {
            font-size: 13px;
          }
        }

        &-list {
          overflow: hidden;
          margin-left: auto;
          margin-right: auto;

          @media (max-width: 767px) {
            margin-right: 18px;
          }

          &:after {
            content: "...";
            position: relative;
            top: 0;
            right: 0;
            display: none;
            height: 16px;
            width: 20px;
            z-index: 9;
            color: #fff;
            font-size: 14px;

            @media (max-width: 445px) {
              font-size: 12px;
              letter-spacing: -0.2px;
              display: block;
            }
          }

          &-item {
            float: left;
            text-transform: lowercase;
            margin-right: 6px;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
            margin-bottom: 15px;

            &.active {
              color: #F4933A;
            }

            @media (max-width: 767px) {
              font-size: 12px;
              letter-spacing: -0.2px;
            }
          }
        }
      }
    }

    &.sub-toolbar {

      &.slim {
        height: 10px;
      }

      ion-title {
        padding-inline-start: 15px;
        -webkit-padding-start: 15px;
        padding-inline-end: 15px;
        -webkit-padding-end: 15px;
      }
    }

    ion-buttons {
      color: #47464B;
      font-size: 22px;


      ion-icon {
        font-size: 38px;

        &.back {
          font-size: 32px;
        }
      }

    }

    button {
      background: transparent;
    }

    ion-button {
      color: #5e98f0;
      font-size: 34px;

      &.simple {
        --padding-start: 0;
        --padding-end: 15px;
        font-size: 16px;
        --background-hover-opacity: 0;

        &.menu {
          --padding-end: 10px;
        }
      }

      &.hidden {
        visibility: hidden;
      }
    }
  }

  ion-menu {

    ion-item {
      --padding-end: 10px;
    }

    .item {
      color: #262626;
      font-size: 16px;

      &.space {
        margin-top: 10px;
      }

      &.half-space {
        margin-top: 5px;
      }

      &.safe-space {
        margin-top: 3px;
      }

      &.copyright {
        font-size: 10px;
        color: #E0E0E0;
        margin-top: 10px;

        &.head {
          font-size: 12px;
          margin-top: 20px;
        }
      }

      &.bottom-logo-space {
        margin-bottom: 65px;
      }

      &.title {
        font-weight: bold;
        margin-top: 15px;

        &.first {
          margin-top: 0;
        }
      }

      a {
        text-decoration: none;
        color: #3583ea;
      }

      ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 25px;

        & > li {
          margin-top: 5px;
        }
      }

      &.line {
        border-bottom: #ffffff 1px solid;
        padding-bottom: 10px;
      }

      &.space-bottom {
        padding-bottom: 20px;
      }
    }

    .list-ios {
      margin-top: 7px;
    }
  }

  .footer {
    height: 40px;
    color: #979797;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
    margin-top: 10px;
  }

  .custom-tabs {
    margin-right: 5px;
    margin-left: 5px;

    ion-grid {
      margin: 0;
      padding: 0;

      ion-row {
        margin: 0;
        padding: 0;
        border-bottom: #6096f6 3px solid;

        &.line-green {
          border-color: #51b9a6;

          ion-col {
            &.active {
              a {
                background: #51b9a6;
              }
            }
          }
        }

        ion-col {
          margin: 0;
          padding: 0;

          &:last-child {
            text-align: right;
          }

          a {
            text-decoration: none;
            color: #6096f6;
            font-size: 18px;
            font-weight: bold;
            padding: 10px;
            width: 60%;
            text-align: center;
            display: inline-block;

            @media (max-width: 760px) {
              width: 95%;
              font-size: 15px;
            }
          }

          &.tray-color {
            a {
              color: #51b9a6;
            }
          }

          &.active {
            a {
              background: #6096f6;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .simple-template {
    background-color: var(--dark_bg_color);
    position: relative;

    ion-button {
      --border-radius: 41px;
    }

    .bottom-logo {
      position: fixed;
      bottom: 15px;
      left: 0;
      width: 100%;
      height: 62px;
      text-align: center;
      background: transparent;
      z-index: 2;

      @media (max-width: 375px) {
        bottom: 0px;
      }
    }

    .content-wrapper {
      width: 320px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      position: relative;
      z-index: 2;

      @media (min-width: 765px) {
        width: 450px;
      }

      .inner-wrapper {
        width: 100%;
        margin-top: 80px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;

        &.native {
          margin-top: 80px;

          @media (max-width: 375px) {
            margin-top: 50px;
          }
        }

        .logo-block {
          text-align: center;
        }

        .form-block {
          margin-top: 20px;

          &.center {
            text-align: center;
          }

          .btn-label {
            text-align: center;
            margin-top: 10px;
            color: #ffffff;
            font-size: 18px;
          }

          &.short {
            max-width: 236px;
            margin: 70px auto 110px;
            text-align: center;

            @media (max-width: 375px) {
              margin: 50px auto 110px;
            }

            a {
              font-weight: 400;
              font-size: 16px;
              line-height: 162%;
              color: #FFFFFF;
              text-decoration: none;
              margin-top: 14px;
              display: block;
            }

            ion-button {
              font-size: 24px;
              font-weight: 600;
              height: 50px;
              margin-top: 15px;
            }

            //@media (max-width: 810px) {
            //  position: absolute;
            //  width: 100%;
            //  max-width: 100%;
            //  bottom: 170px;
            //  left: 0;
            //  margin-bottom: 0;
            //  z-index: 2;
            //
            //  @media (min-height: 812px) {
            //    bottom: 220px;
            //  }
            //
            //  ion-button {
            //    max-width: 220px;
            //    margin-left: auto;
            //    margin-right: auto;
            //  }
            //}
          }

          .title {
            font-size: 20px;
            color: #ffffff;
            font-weight: 500;
          }

          ion-item {
            --highlight-background: transparent;
          }

          .item {
            padding-left: 0px;
            min-height: auto;
            margin-top: 15px;

            &.submit-block {
              margin-top: 0px;

              &.with-margin-top {
                margin-top: 10px;
              }

              ion-button {
                width: 100%;
                height: 40px;
                font-size: 16px;
                margin-left: 0px;
                margin-right: 0px;
              }
            }

            &.second-action {
              margin-top: 38px;

              &.no-padding {
                margin-top: 25px;
              }

              ion-button {
                width: 100%;
                height: 40px;
                font-size: 16px;
                border-radius: 4px;
                margin-left: 0px;
                margin-right: 0px;
              }
            }

            &.center {
              text-align: center;
            }

            &.error {
              color: var(--error-message-color);
              margin-top: 3px;
              text-align: right;
              font-size: 13px;

              .label {
                margin: 0;
                white-space: normal;
              }
            }

            &.text {
              margin-top: 15px;
              padding-left: 11px;
              color: #D8D8D8;

              &.p-bottom {
                margin-bottom: 30px;
              }

              a {
                color: #6096f6;
                text-decoration: none;
              }

              &.large {
                padding-left: 0;

                .label {
                  font-size: 15px;
                }
              }

              &.right {
                padding-left: 0;
              }

              &.center {
                padding-left: 0;
                text-align: center;

                a {
                  margin-left: auto;
                  margin-right: auto;
                }
              }

              &.paragraph {
                padding-left: 0px;

                .label {
                  white-space: normal;

                  p {
                    margin-bottom: 15px;
                    font-size: 14px;
                    line-height: 1.5;
                    color: #D8D8D8;
                  }
                }
              }

              .label {
                font-size: 17px;
                letter-spacing: 0.3px;
                color: #6096f6;
                margin-top: 0px;
                margin-bottom: 0px;
              }
            }

            &.policy {
              margin-top: 0px;

              ion-checkbox {
                margin-right: 10px;
                margin-top: 0px;
                margin-bottom: 0px;
              }

              ion-label {
                font-size: 16px;
                color: #cccccc;
                z-index: 99;

                a {
                  color: #cccccc;
                  text-decoration: underline;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }

            &.remember-me {
              margin-top: 0px;

              &.mt-10 {
                margin-top: 10px;
              }

              &.mb-10 {
                margin-bottom: 10px;
              }

              ion-checkbox {
                margin-right: 10px;
                --background: transparent;
              }

              ion-label {
                font-size: 16px;
                color: #ffffff;
                position: relative;
                z-index: 100;

                a {
                  color: #5E98F0;
                  text-decoration: underline;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }

              &.np {
                ion-checkbox {
                  margin-bottom: 7px;
                  margin-top: 7px;
                }

                ion-label {
                  margin-bottom: 7px;
                  margin-top: 7px;
                }
              }
            }

            ion-input {
              border: rgba(255, 255, 255, 0.23) 1px solid;
              border-radius: 3px;
              background-color: #262626;

              &:hover {
                border-color: #ffffff;
              }

              input::placeholder {
                color: #bebebe;
                opacity: 1;
              }

              .native-input {
                font-size: 15px;
                color: #ffffff;
                padding: 18.5px 14px;
                font-weight: 500;
                height: 54px;
              }
            }

            .item-inner {
              padding-right: 0;
              border: 0;
              box-shadow: 0 0 0;
            }
          }

          .button {
            text-transform: none;
          }
        }
      }
    }
  }

  .page-wrapper {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;

    &.narrow {
      max-width: 840px;
    }
  }

  ion-footer {
    ion-toolbar {
      max-width: 100%;

      ion-button {
        font-size: 16px;
        text-transform: none;
        width: 99%;
        max-width: 400px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      ion-title {
        padding-top: 0;
        padding-inline-start: 13px;
        -webkit-padding-start: 13px;
        padding-inline-end: 13px;
        -webkit-padding-end: 13px;
      }
    }
  }

}

.ios {
  ion-toolbar {
    ion-title {
      padding-inline-start: 55px;
      -webkit-padding-start: 55px;
      padding-inline-end: 15px;
      -webkit-padding-end: 15px;
      padding-top: 4px !important;

      &.without-back-btn {
        padding-inline-start: 15px;
        -webkit-padding-start: 15px;
      }

      &.with-cancel-btn {
        padding-inline-end: 70px;
        -webkit-padding-end: 70px;
      }

      &.with-menu-btn {
        padding-inline-end: 50px;
        -webkit-padding-end: 50px;
      }
    }
  }
}

.item {
  .highlight {
    font-weight: 700;
  }
}

.alert-button {
  &.main {
    font-weight: bold;
  }

  &.secondary.sc-ion-alert-ios {
    &:last-child {
      font-weight: normal;
    }
  }
}

//.player-type-wrap-1 {
//  .action-bar {
//    .tip {
//      @media (max-width: 767px) {
//        span {
//          display: block;
//          margin-left: 16px;
//
//          &.np {
//            margin-left: 0;
//          }
//        }
//      }
//    }
//  }
//}

.header-avatar {
  overflow: hidden;
  width: 42px;
  height: 42px;
  border-radius: 50%;

  &_btn {
    padding-left: 0;
    padding-right: 8px;
    padding-top: 5px;
    cursor: pointer;
  }
}

/*.ios {
  ion-header {
    &.native {
      padding-top: 57px;
    }
  }
}*/

.info-wrap_note {
  .qrcode {
    text-align: center;
    canvas {
      width: 100% !important;;
      height: auto !important;
      max-width: 280px;
    }
  }
}

.tip-qr {
  .qrcode {
    text-align: center;
    canvas {
      width: 100% !important;;
      height: auto !important;
    }
  }
}

.swiper-pagination-bullet {
  border: #ffffff 1px solid !important;
  width: 12px;
  height: 12px;
}

.swiper .swiper-pagination-bullet-active {
  background: #666670;
}

.swiper-slide, .swiper-pagination {
  --swiper-pagination-bullet-inactive-opacity: 0.35;
}

.swiper-pagination {
  padding-left: 34px;
  padding-right: 34px;
}

.home-sliders {
  .swiper {
    .swiper-slide {
      align-items: stretch;
      height: auto;
    }
  }
}

.small-slider {
  .swiper-pagination {
    padding-left: 0;
    padding-right: 0;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2px;
  }
}

.swiper-button-prev {
  height: 34px;
  width: 34px;
  left: 2px;
  bottom: 3px;
  background: url('assets/img/slider-arrow-prev.svg');
  background-size: contain;
  cursor: pointer;
  position: absolute;
  z-index: 11;
  opacity: 0.85;

  &.swiper-button-disabled {
    cursor: default;
    opacity: 0.4;
  }
}

.swiper-button-next {
  height: 34px;
  width: 34px;
  bottom: 3px;
  background: url('assets/img/slider-arrow-next.svg');
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 2px;
  z-index: 11;
  opacity: 0.85;

  &.swiper-button-disabled {
    cursor: default;
    opacity: 0.4;
  }
}

.color-change-2x {
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both;
}

.tip-guideInfo {
  .content {
    b {
      color: #1E5598;
    }

    ol {
      margin: 0 0 0 17px;
      padding: 0;

      li {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.guide-dot {
  &.active {
    background: #F4933A80 !important;
  }
}

ion-footer {
  height: 55px;
  background-color: var(--footer_bg_color);
  position: relative;
  box-shadow: 0 0 0 !important;

  &.highlight {
    background-color: #ffffff !important;
    height: 62px;

    ion-toolbar {
      --background: #ffffff !important;
    }
  }

  &.transparent {
    background-color: #f8f8f9 !important;
    height: auto;

    ion-toolbar {
      --background: #f8f8f9 !important;
    }
  }

  &.vote {
    height: 157px;
    border-radius: 32px 32px 0px 0px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(2px);
  }

  ion-toolbar {
    --background: var(--footer_bg_color);
    max-width: 840px !important;

    ion-title {
      padding-top: 0px !important;
      text-align: left !important;
      font-size: 12px !important;
      color: #8D92A8 !important;
      line-height: 55px;
      position: relative;
      max-width: 1420px;
      margin-right: auto;
      margin-left: auto;

      @media (max-width: 600px) {
        text-align: center !important;
      }

      a {
        color: #8D92A8;
      }

      .logo {
        position: absolute;
        right: 0;
        top: 7px;

        @media (max-width: 600px) {
          display: none;
        }

        a {
          color: #ffffff;

          span {
            vertical-align: top;
            padding-top: 9px;
            margin-right: 7px;
            line-height: 23px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.footer-md.transparent::before {
  display: none;
}

.footer-ios.transparent ion-toolbar:first-of-type {
  --border-width: 0 0 0;
}

.sub-toolbar {
  &-wrap {
    margin-top: 80px;
  }

  &-logo {
    margin-bottom: 20px;

    &_label {
      margin-bottom: 10px;
    }
  }

  &-profile {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.header-md::after {
  background: transparent;
}

.ios {
  ion-footer {
    ion-toolbar {
      ion-title {
        padding-top: 0 !important;
      }
    }
  }

  .tip-modal {
    &.tip-qr {
      padding-top: 35% !important;

      .close-btn {
        top: 25px !important;
      }
    }
  }
}

.web-image-upload {
  .shot {
    backdrop-filter: blur(4px);
  }
}

.web-scan {
  .shot {
    backdrop-filter: blur(2px);

    &.shot-clean {
      backdrop-filter: none;
    }
  }
}

.results {
  .highlight {
    color: #666670;
    font-weight: 500;
  }
}

.lang-fr {
  .recent-inst-title,
  .score-board_filters {
    ul {
      @media (max-width: 767px) {
        text-align: left !important;
      }

      & > li {
        @media (max-width: 767px) {
          line-height: 24px;
          margin-left: 0 !important;
          margin-right: 20px !important;
        }
      }
    }
  }

  .tip-modal {
    .action.center {
      ion-button {
        width: 76% !important;
      }
    }
  }

  .player-type-wrap-1 {
    .action-bar {
      .tip {
        @media (max-width: 840px) {
          padding-top: 5px !important;
        }

        @media (max-width: 767px) {
          font-size: 13px !important;
        }
      }
    }
  }

  .welcome_bottom-nav_desc {
    font-size: 14px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.lang-de {
  .recent-inst-title,
  .score-board_filters {
    ul {
      @media (max-width: 767px) {
        text-align: left !important;
      }

      & > li {
        @media (max-width: 767px) {
          line-height: 24px;
          margin-left: 0 !important;
          margin-right: 20px !important;
        }
      }
    }
  }

  .player-type-wrap-1 {
    .action-bar {
      .tip {
        @media (max-width: 840px) {
          padding-top: 5px !important;
        }

        @media (max-width: 767px) {
          font-size: 13px !important;
        }
      }
    }
  }

  .welcome_bottom-nav_desc {
    font-size: 14px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .welcome_bottom-nav_action {
    ion-button {
      &.small {
        font-size: 14px !important;
      }
    }
  }

  .info {
    &-wrap {
      &_note {
        &-guide {
          &_action {
            ion-button {
              max-width: 310px;
            }
          }
        }
      }
    }
  }
}

.lang-he {
  .welcome_bottom-nav_desc {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.lang-ru {
  .recent-inst-title,
  .score-board_filters {
    ul {
      @media (max-width: 767px) {
        text-align: left !important;
      }

      & > li {
        @media (max-width: 767px) {
          line-height: 24px;
          margin-left: 0 !important;
          margin-right: 20px !important;
        }
      }
    }
  }

  .welcome_bottom-nav_desc {
    font-size: 14px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .welcome_bottom-nav_action {
    ion-button {
      &.small {
        font-size: 14px !important;
      }
    }
  }
}

.lang-es {
  .recent-inst-title,
  .score-board_filters {
    ul {
      @media (max-width: 767px) {
        text-align: left !important;
      }

      & > li {
        @media (max-width: 767px) {
          line-height: 24px;
          margin-left: 0 !important;
          margin-right: 20px !important;
        }
      }
    }
  }

  .welcome_bottom-nav_desc {
    font-size: 14px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .welcome_bottom-nav_action {
    ion-button {
      &.small {
        font-size: 15px !important;
      }
    }
  }

  .player-type-wrap-1 {
    .action-bar {
      .tip {
        @media (max-width: 840px) {
          padding-top: 5px !important;
        }

        @media (max-width: 767px) {
          font-size: 13px !important;
        }
      }
    }
  }

  .tip-modal {
    .action.center {
      ion-button {
        width: 76% !important;
      }
    }
  }
}

.fullPageWidth {
  width: 100vw;
}

.fullPageHeight {
  height: 100vh;
}

.alert-body-320px {
  .alert-wrapper {
    min-width: 320px;
  }
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #F4F4F4;
  }
  100% {
    background: #dfe2f1;
  }
}
@keyframes color-change-2x {
  0% {
    background: #F4F4F4;
  }
  100% {
    background: #dfe2f1;
  }
}

