// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --swiper-pagination-color: #666670;

  --ion-font-family: 'Lato';
  /** primary **/
  --ion-color-primary: #6096F6;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #588be6;
  --ion-color-primary-tint: #699efd;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #F47121;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e4691e;
  --ion-color-warning-tint: #fa7321;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Custom vars **/
  --dark_bg_color: #10141C;
  --light_bg_color: #ffffff;
  --light_grey_bg_color: #DADADA;
  --grey_text_color: #5B5E71;
  --primary-link-color: #6096F6;
  --error-message-color: #f32853;
  --footer_bg_color: #262626;
  --text: #666670;
  --mid-dark-grey: #47464B;
  --lj-light-grey: #F2F3F8;
  --lj-mid-grey: #97989B;
  --lj-blue: #3266FF;
}

ion-input,
ion-textarea {
  --highlight-color-valid: #000000 !important;
  --highlight-color-invalid: #000000 !important;
  --highlight-color: #000000 !important;
}

.input-highlight.sc-ion-input-md,
.textarea-highlight.sc-ion-textarea-md {
  display: none !important;
}


ion-toolbar {
  --background: transparent;
  --min-height: 60px;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;

  &.sub-toolbar {
    --min-height: 36px;

    &.top {
      --min-height: 0px;
    }

    /*&.collapsed {
      --min-height: 120px;
    }*/
  }
}

ion-footer {

  ion-toolbar {
    --background: #ffffff;
    --min-height: 56px;
  }
}

.ios {
  ion-toolbar {
    &.sub-toolbar {
      &.top {
        --min-height: 200px;
      }

      &.collapsed {
        --min-height: 92px;

        @media (max-width: 700px) {
          --min-height: 122px;
        }

        @media (max-width: 390px) {
          --min-height: 154px;
        }

        @media (max-width: 375px) {
          --min-height: 184px;
        }
      }
    }
  }
}

ion-item {
  --inner-border-width: 0;
  --border-width: 0;
  --min-height: auto;
  --background-focused: transparent;
  --background: transparent;
  --highlight-background: transparent;
}

ion-menu {
  --background: rgba(255, 255, 255, 0.9);
  --ion-background-color: rgba(255, 255, 255, 0.9);
  --width: 290px;

  @media (max-width: 375px) {
    --width: 100%;
  }

  ion-content {
    --ion-background-color: rgba(255, 255, 255, 0.9);
  }

  ion-item {
    --ion-color-primary: #d8d8d8;
    --min-height: 26px;
  }

}

ion-list {
  --ion-item-background: transparent;
}

ion-button {
  letter-spacing: 0.3px;
  --border-radius: 4px;
  height: 40px;
  text-transform: none;
  font-size: 16px;

  &.button-round {
    --border-radius: 41px;
  }
}

ion-checkbox {
  --border-color: #787878;
  --background: #ffffff;
}

ion-menu-button {
  --color: #5E98F0;
}

ion-content {
  --ion-background-color: var(--light_grey_bg_color);
}

ion-modal {
  --background: #ffffff !important;
  --border-radius: 0 !important;
  --max-width: 360px !important;
  --height: 80% !important;
  color: #414450;

  &.modal-network {
    --max-height: 587px;
  }

  &.modal-imagePreview {
    --height: auto !important;
    --border-radius: 32px !important;
  }

  &.modal-previewCaptureImage {
    --border-radius: 5px !important;
    --height: auto !important;
    --max-height: 96% !important;
    --width: 90% !important;
    --max-width: 380px !important;
    --overflow: auto !important;
  }

  &.modal-alert {
    --height: auto !important;
    --border-radius: 32px !important;
  }

  &.modal-beta {
    --height: auto !important;
    --border-radius: 32px !important;
    --max-width: 320px !important;
  }

  &.modal-previewPDF {
    --border-radius: 32px !important;
    --max-width: 800px !important;
    --width: 96%;
    --height: 90% !important;
  }

  &.modal-catalogSlider {
    --border-radius: 32px !important;
    --max-width: 90vh !important;
    --width: 96%;
    --height: auto !important;

    @media (max-width: 767px) {
      --height: 100% !important;
      --max-width: 100% !important;
      --width: 100%;
      --border-radius: 0 !important;
    }
  }

  &.modal-invite {
    --height: 200px !important;
  }

  &.modal-scanInstruction {
    --height: 525px !important;
    --border-radius: 32px !important;
  }

  &.modal-guideInfo {
    --height: auto !important;
    --background: #ffffff !important;
    --max-width: 300px !important;
    align-items: start;
    margin-top: 90px;
  }

  &.modal-welcome {
    --height: 96% !important;
    --max-height: 760px;
    z-index: 999999 !important;
    --background: linear-gradient(180deg, #0D66ED 0%, #04132A 100%) !important;

    @media (max-width: 767px) {
      --height: 100% !important;
      --max-width: 100% !important;
      --max-height: 100%;
    }
  }

  &.modal-qr {
    --background: #ffffff !important;
    --height: 575px !important;
    z-index: 999999 !important;

    @media (max-width: 767px) {
      --height: 100% !important;
      --max-width: 100% !important;
    }
  }
}

